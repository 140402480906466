import React from "react";
import { FaEnvelope, FaRobot } from "react-icons/fa";

function ContactUs() {
  return (
    <div className="min-h-screen p-6 bg-gray-800 text-gray-700 bg-white">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold mb-6">Contact Us</h1>

        <div className="mb-10">
          <h2 className="text-3xl font-semibold mb-4 flex items-center">
            <FaRobot className="mr-2" /> Chat with GojoBot
          </h2>
          <p>
            Have a question? Our GojoBot is here to help 24/7. Chat with us directly on the app for instant support.
          </p>
        </div>

        <div>
          <h2 className="text-3xl font-semibold mb-4 flex items-center">
            <FaEnvelope className="mr-2" /> Email Us
          </h2>
          <p>
            For more detailed inquiries, feel free to reach out to us at 
            <a href="mailto:info@gojodating.com" className="text-blue-400 hover:text-blue-300 ml-1">
              info@gojodating.com
            </a>. We're always here to help!
          </p>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
