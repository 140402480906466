import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

function VerifyOTPInputScreen() {
  const [OTP, setOTP] = useState("");
  const [alertMessage, setAlertMessage] = useState(null);
  const navigate = useNavigate();
  const { phoneNumber } = useParams();

  const handleSubmit = async () => {
    await fetch(`${process.env.REACT_APP_SERVER_URL}/user/verifyOTP`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
      },
      body: JSON.stringify({ phoneNumber: "+" + phoneNumber, OTP }),
    })
      .then(async (res) => {
        if (res.status === 200) {
          let responseJson = await res.json();
          setAlertMessage({
            type: "success",
            message: responseJson.message,
          });
          navigate(`/confirm-deletion/${phoneNumber}`);
        } else if (res.status === 201) {
          let responseJson = await res.json();
          setAlertMessage({
            type: "failed",
            message: responseJson.message,
          });
        } else {
          let responseJson = await res.json();
          setAlertMessage({
            type: "error",
            message: "Something went wrong",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setAlertMessage({
          type: "error",
          message: "Something went wrong",
        });
      });
  };

  useEffect(() => {
    if (alertMessage) {
      const timeout = setTimeout(() => {
        setAlertMessage(null);
      }, 3000); // Adjust the duration (in milliseconds) as needed

      return () => clearTimeout(timeout);
    }
  }, [alertMessage]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-6">
      {alertMessage && (
        <div
          className={`${
            alertMessage.type === "success" ? "bg-green-500" : "bg-red-500"
          } text-white rounded-lg py-2 px-4 mb-4`}
        >
          {alertMessage.message}
        </div>
      )}
      <input
        type="text"
        value={OTP}
        onChange={(e) => setOTP(e.target.value)}
        className="border-2 border-gray-300 rounded-lg py-2 px-4 mb-4"
        placeholder="Enter verification code"
      />
      <button
        onClick={handleSubmit}
        className="bg-blue-500 text-white rounded-lg py-2 px-4 hover:bg-blue-600"
      >
        Verify OTP
      </button>
    </div>
  );
}

export default VerifyOTPInputScreen;
