import React from "react";
import { FaBook, FaUserShield, FaInfoCircle, FaQuestionCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function HelpCard({ icon, title, description, onClick }) {
  return (
    <div className="bg-gray-400 rounded-lg shadow-md p-4 flex flex-col items-start">
      <div className="flex items-center space-x-4 mb-2">
        {icon}
        <h2 className="text-xl font-semibold">{title}</h2>
      </div>
      <p className="mb-4 flex-grow">{description}</p>
      <button onClick={onClick} className="bg-customColor text-white rounded-lg py-2 px-4 hover:bg-customColor2 w-full mt-auto">
        Learn More
      </button>
    </div>
  );
}

function Help() {
  const navigate = useNavigate();
  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-6">
      <h1 className="text-3xl font-bold text-center mb-6 text-white">Help & Support</h1>
      <div className="w-full max-w-4xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <HelpCard
          icon={<FaBook className="text-blue-500 text-2xl" />}
          title="Guidelines"
          description="Understand the do's and don'ts to ensure a safe and enjoyable experience on our platform."
          onClick={() => handleNavigate('/guidelines')}
        />
        <HelpCard
          icon={<FaUserShield className="text-green-500 text-2xl" />}
          title="Privacy"
          description="Learn about how we protect your data and respect your privacy."
          onClick={() => handleNavigate('/privacy')}
        />
        <HelpCard
          icon={<FaInfoCircle className="text-yellow-500 text-2xl" />}
          title="About"
          description="Discover more about our mission, vision, and values."
          onClick={() => handleNavigate('/about')}
        />
        <HelpCard
          icon={<FaQuestionCircle className="text-red-500 text-2xl" />}
          title="Need More Help?"
          description="Find options to delete or deactivate your account, cancel subscriptions, or contact developers."
          onClick={() => handleNavigate('/help-actions')}
        />
      </div>
    </div>
  );
}

export default Help;
