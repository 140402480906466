import React, { useState } from "react";
import Logo from "../assets/GojoLogo.webp";
import { Link } from "react-router-dom";

function Header({ isPointBasedPayment }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // If it's the point-based payment page, render simplified header
  if (isPointBasedPayment) {
    return (
      <header className="flex justify-center items-center py-4 px-6">
        <div className="shrink-0">
          <img src={Logo} alt="Gojo Logo" className="h-12 md:h-24" />
        </div>
      </header>
    );
  }

  // Regular header for other pages
  return (
    <header className="flex justify-between items-center py-4 px-6">
      <div className="shrink-0">
        <Link to="/">
          <img src={Logo} alt="Gojo Logo" className="h-12 md:h-24" />
        </Link>
      </div>

      <div className="md:hidden">
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="text-white focus:outline-none"
        >
          <svg
            className="h-8 w-8"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path
              d={
                isMenuOpen ? "M18 6L6 18M6 6l12 12" : "M3 6h18M3 12h18M3 18h18"
              }
            />
          </svg>
        </button>
      </div>

      <nav
        className={`${
          isMenuOpen ? "flex" : "hidden"
        } flex-col md:flex md:flex-row md:space-x-4`}
      >
        <Link
          to="/guidelines"
          className="text-white hover:text-gray-300 mt-2 md:mt-0"
        >
          Guidelines
        </Link>
        <Link
          to="/privacy"
          className="text-white hover:text-gray-300 mt-2 md:mt-0"
        >
          Privacy
        </Link>
        <Link
          to="/about"
          className="text-white hover:text-gray-300 mt-2 md:mt-0"
        >
          About
        </Link>
        <Link
          to="/help"
          className="text-white hover:text-gray-300 mt-2 md:mt-0"
        >
          Help
        </Link>
      </nav>
    </header>
  );
}

export default Header;
