import React from "react";
import { FaGlobeAfrica, FaBuilding, FaHandsHelping, FaComments } from "react-icons/fa";

function About() {
  const aboutSections = [
    {
      title: "Connecting Communities",
      content: "At the heart of Gojo Dating App is a vision to connect the Ethiopian and Eritrean communities across the globe. Our platform is designed to help users find meaningful and fruitful connections, fostering relationships that resonate with shared cultural values and backgrounds.",
      icon: FaGlobeAfrica
    },
    {
      title: "Innovative Origins",
      content: "Developed by Fetatech LLC, a forward-thinking startup based in the USA, Gojo Dating App combines modern technology with cultural insights to create a unique dating experience. Our team is dedicated to innovating ways to bring people together in the digital age.",
      icon: FaBuilding
    },
    {
      title: "Cultural Integrity",
      content: "Respecting and upholding the moral, cultural, and ethical principles of the Ethiopian and Eritrean communities is paramount to us. We believe in creating a platform that not only connects people but also honors and celebrates our rich cultural heritage.",
      icon: FaHandsHelping
    },
    {
      title: "We Value Your Feedback",
      content: "Your insights and suggestions are vital to our growth. We are always open to feedback and eager to implement features that enhance user experience and satisfaction. Your voice is a guiding force in our journey to continually improve and innovate.",
      icon: FaComments
    }
  ];

  return (
    <div className="min-h-screen p-6 text-gray-700 bg-white">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold mb-6">About Us</h1>

        {aboutSections.map((section, i) => (
          <div key={i} className="flex items-start mb-6">
            {React.createElement(section.icon, { className: "mr-2 mt-1 text-green-500 h-6 w-6" })}
            <div>
              <h2 className="text-2xl font-semibold mb-2 text-gray-400">{section.title}</h2>
              <p>{section.content}</p>
            </div>
          </div>
        ))}

      </div>
    </div>
  );
}

export default About;
