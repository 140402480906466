import React, { useState, useEffect } from "react";
import {
  FaEye,
  FaHeart,
  FaMapMarkerAlt,
  FaUndo,
  FaComments,
  FaUserSecret,
  FaUserCheck,
} from "react-icons/fa";

const minimumPointToBuyPremium = 3000;
const minimumPaymentAmount = 300; // Birr
const PremiumFeatures = {
  CHAT_WITH_MATCHING: { label: "Chat with Matching", icon: <FaComments /> },
  SEND_LIKE: { label: "Send Like", icon: <FaHeart className="text-red-500" /> },
  INCOGNITO_MODE: {
    label: "Incognito Mode",
    icon: <FaUserSecret className="text-gray-700" />,
  },
  SEE_LIKE: { label: "See Likes", icon: <FaEye /> },
  SET_PREFERRED_LOCATION: {
    label: "Set Preferred Location",
    icon: <FaMapMarkerAlt />,
  },
  SHOW_ME_ONLY_VERIFIED: {
    label: "Show me only verified users",
    icon: <FaUserCheck />,
  },
  RESET_POTENTIAL_MATCHES: {
    label: "Reset Potential Matches",
    icon: <FaUndo />,
  },
};

const PremiumFeaturePointMap = {
  CHAT_WITH_MATCHING: 200,
  SEND_LIKE: 50,
  SET_PREFERRED_LOCATION: 200,
  SHOW_ME_ONLY_VERIFIED: 200,
  RESET_POTENTIAL_MATCHES: 200,
  INCOGNITO_MODE: 500,
};

// Discounts based on points thresholds
const getDiscountedPrice = (points) => {
  let basePrice = points / 10; // Base price calculation

  if (points >= 20000) {
    return basePrice * 0.5; // Apply a 50% discount
  } else if (points >= 10000) {
    return basePrice * 0.75; // Apply a 25% discount
  } else if (points >= 7000) {
    return basePrice * 0.8; // Apply a 20% discount
  } else if (points >= 5000) {
    return basePrice * 0.9; // Apply a 10% discount
  }

  return basePrice; // No discount for points below 3000
};

const Loading = () => (
  <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
    <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-customColor2"></div>
      <p className="mt-4 text-gray-700">Processing your payment...</p>
    </div>
  </div>
);

const Alert = ({ type, message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div
      className={`fixed top-4 right-4 left-4 sm:left-auto sm:w-96 ${
        type === "success" ? "bg-green-500" : "bg-red-500"
      } text-white rounded-lg py-3 px-4 shadow-lg transform transition-all duration-500 ease-in-out z-50`}
    >
      <div className="flex justify-between items-center">
        <span>{message}</span>
        <button
          onClick={onClose}
          className="text-white hover:text-gray-200 focus:outline-none"
        >
          ×
        </button>
      </div>
    </div>
  );
};

const PaymentPackages = () => {
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [userId, setUserId] = useState("");
  const [isTest, setIsTest] = useState(false);
  const [selectedPoints, setSelectedPoints] = useState(7000);
  const [customPrice, setCustomPrice] = useState(getDiscountedPrice(7000));
  const [customPointsError, setCustomPointsError] = useState("");

  useEffect(() => {
    const url = new URL(window.location.href);
    const userId_ = url.pathname.split("/").pop();
    setUserId(userId_);

    // Extract is_test parameter and set default to false
    const isTestParam = url.searchParams.get("is_test");
    setIsTest(isTestParam === "true");
  }, []);

  const discountPackages = [
    { points: 3000, price: getDiscountedPrice(3000) },
    { points: 7000, price: getDiscountedPrice(7000) },
    { points: 10000, price: getDiscountedPrice(10000) },
    { points: 20000, price: getDiscountedPrice(20000) },
  ];

  // Calculate price when points change
  useEffect(() => {
    if (selectedPoints >= minimumPointToBuyPremium) {
      let price = getDiscountedPrice(selectedPoints);
      // Ensure minimum price requirement is met
      price = Math.max(price, minimumPaymentAmount);
      setCustomPrice(price);
      setCustomPointsError("");
    } else if (selectedPoints) {
      setCustomPointsError(
        `Minimum points to buy is ${minimumPointToBuyPremium} (${minimumPaymentAmount} Birr)`
      );
    }
  }, [selectedPoints]);

  const isValidPurchase = () => {
    return selectedPoints >= minimumPointToBuyPremium && customPrice >= minimumPaymentAmount;
  };

  const showAlert = (type, message) => {
    setAlertMessage({ type, message });
  };

  const clearAlert = () => {
    setAlertMessage(null);
  };

  const handlePayment = async () => {
    if (!isValidPurchase()) {
      showAlert("error", `The minimum purchase amount is ${minimumPaymentAmount} Birr.`);
      return;
    }

    setLoading(true);
    try {
      // Use different server URL based on isTest flag
      const serverUrl = isTest
        ? process.env.REACT_APP_SERVER_URL_TEST
        : process.env.REACT_APP_SERVER_URL;
      const amount = isTest ? 1 : customPrice; //making it 1 for test
      const res = await fetch(
        `${serverUrl}/subscription/initiatePointBasedSubscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          },
          body: JSON.stringify({
            userId,
            amount: amount,
            paymentMethod: "chapa",
            point: selectedPoints,
          }),
        }
      );

      const responseJson = await res.json();
      if (res.status === 200) {
        window.location.href = responseJson.chapaPaymentUrl;
      } else {
        setLoading(false);
        showAlert("error", responseJson.message || "Something went wrong");
      }
    } catch (error) {
      setLoading(false);
      showAlert("error", "Something went wrong. Please try again.");
    }
  };

  const handlePointsChange = (e) => {
    const points = parseInt(e.target.value, 10);
    if (!points && points !== 0) {
      setSelectedPoints("");
      return;
    }
    setSelectedPoints(points);
  };

  const handlePackageSelect = (package_) => {
    setSelectedPoints(package_.points);
  };

  const getDiscountLabel = (points) => {
    if (points >= 20000) return "50%";
    if (points >= 10000) return "25%";
    if (points >= 7000) return "20%";
    if (points >= 5000) return "10%";
    return null;
  };

  return (
    <div className="bg-gray-100 min-h-screen p-6">
      {loading && <Loading />}

      {alertMessage && (
        <Alert
          type={alertMessage.type}
          message={alertMessage.message}
          onClose={clearAlert}
        />
      )}

      <div className="container mx-auto">
        <div className="bg-white p-2 rounded-lg shadow-lg mb-8">
          <h3 className="text-2xl font-bold text-gray-800 mb-3">
            Purchase Gojo Points
          </h3>
          
          <div className="space-y-6">
            <div>
              <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-3 mb-6">
                {discountPackages.map((package_) => {
                  const discountLabel = getDiscountLabel(package_.points);
                  return (
                    <div
                      key={package_.points}
                      onClick={() => handlePackageSelect(package_)}
                      className={`
                        border rounded-lg p-3 cursor-pointer transition-all duration-200
                        ${
                          selectedPoints === package_.points
                            ? 'border-gray-400 bg-gray-50 shadow-md ring-1 ring-gray-400'
                            : 'border-gray-200 hover:border-gray-300 hover:bg-gray-50 hover:shadow'
                        }
                      `}
                    >
                      <h4 className="text-xl font-bold text-gray-800">
                        {package_.points} Points
                      </h4>
                      <p className="text-sm text-gray-600 mt-1">
                        {Math.round(package_.price)} Birr
                      </p>
                      {discountLabel && (
                        <p className="text-xs text-green-600 mt-1">
                          {discountLabel} discount
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
              
              <div className="mb-6">
                <label className="block text-gray-700 mb-2">Custom Points</label>
                <div className="flex flex-col sm:flex-row gap-4">
                  <div className="flex-grow">
                    <input
                      type="number"
                      value={selectedPoints}
                      onChange={handlePointsChange}
                      placeholder={`Enter points (minimum ${minimumPointToBuyPremium})`}
                      className="border border-gray-300 rounded-lg p-3 w-full focus:border-customColor2 focus:ring focus:ring-customColor2 focus:outline-none"
                    />
                    {customPointsError && (
                      <p className="text-red-500 text-sm mt-1">
                        {customPointsError}
                      </p>
                    )}
                  </div>
                </div>
                
                <div className="flex justify-center items-center mt-3 bg-gray-100 border border-gray-200 rounded-lg p-3">
                  <span className="text-gray-500 mr-2">Total Price:</span>
                  <span className="text-xl font-semibold text-gray-800">{Math.round(customPrice)} Birr</span>
                </div>
                
                <p className="text-xs text-gray-500 mt-1">
                  Minimum payment amount: {minimumPaymentAmount} Birr
                </p>
              </div>
              
              <button
                onClick={handlePayment}
                disabled={!isValidPurchase()}
                className={`
                  bg-green-600 text-white rounded-lg py-3 px-6 w-full sm:w-auto 
                  text-lg font-medium transform hover:bg-green-700 
                  hover:shadow-lg transition duration-200
                  ${!isValidPurchase() ? "opacity-50 cursor-not-allowed" : ""}
                `}
              >
                Buy Points
              </button>
            </div>
          </div>
        </div>
        
        <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
          <h3 className="text-xl font-semibold text-gray-800 mb-3">
            Premium Features and Costs
          </h3>
          
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead className="bg-gray-50 border-b">
                <tr>
                  <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Feature</th>
                  <th className="px-3 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Cost</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {Object.keys(PremiumFeaturePointMap).map((featureKey) => (
                  <tr key={featureKey} className="hover:bg-gray-50">
                    <td className="px-3 py-3 whitespace-nowrap">
                      <div className="flex items-center">
                        <span className="flex-shrink-0 text-customColor2 mr-2">
                          {PremiumFeatures[featureKey].icon}
                        </span>
                        <span className="text-sm text-gray-800 truncate max-w-[180px]">
                          {PremiumFeatures[featureKey].label}
                        </span>
                      </div>
                    </td>
                    <td className="px-3 py-3 whitespace-nowrap text-right text-sm font-medium text-customColor2">
                      {PremiumFeaturePointMap[featureKey]} points
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPackages;
